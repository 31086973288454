import { Box, Card, CardContent, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { DetailTools } from "../../shared/components";
import { useAgentContext } from "../../shared/contexts";
import { useVForm, VForm } from "../../shared/forms";
import { BaseLayout } from "../../shared/layouts";
import {
  AgentService,
  IAgentDetail,
} from "../../shared/services/api/agent/AgentService";

export const Anotacoes = () => {
  const { save, formRef } = useVForm();
  const { agentId } = useAgentContext();

  const [agent, setAgent] = useState<IAgentDetail>();
  const [anotacao, setAnotacao] = useState("");

  useEffect(() => {
    AgentService.getById(agentId).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
      } else {
        setAgent(result);
        setAnotacao(result.anotacoes);
      }
    });
  }, [agentId]);

  const handleSalve = (anotacoes: string) => {
    // if (agentId === "nova") {
    //   // AgentService.create(agent).then((result) => {
    //   //   if (result instanceof Error) {
    //   //     alert(result.message);
    //   //     console.log(result);
    //   //   }
    //   // });
    // } else {
    AgentService.updateAnotacoesById(Number(agentId), anotacoes).then(
      (result) => {
        if (result instanceof Error) {
          alert(result.message);
          console.log(result);
        } else {
          alert("Anotações salvas");
        }
      }
    );
  };

  return (
    <BaseLayout
      titulo="Anotações"
      barraDeFerramentas={
        <DetailTools
          mostrarBotaoApagar={false}
          mostrarBotaoNovo={false}
          mostrarBotaoVoltar={false}
          aoClicarEmSalvar={save}
        />
      }
    >
      <VForm ref={formRef} onSubmit={() => handleSalve(anotacao)}>
        <Box width="100%" display="flex">
          <Grid container margin={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardContent>
                    <Box
                      padding={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <TextField
                        fullWidth
                        id="outlined-multiline-static"
                        multiline
                        rows={29}
                        defaultValue={agent?.anotacoes}
                        onChange={(e) => setAnotacao(e.target.value)}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </BaseLayout>
  );
};
