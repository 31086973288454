import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  CardActions,
  CardHeader,
  Grid,
  Icon,
  IconButton,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  AgentService,
  IAgentList,
} from "../../services/api/agent/AgentService";
import { ListingTools } from "../listing-tools/ListingTools";
import { useAgentContext, useAuthContext } from "../../contexts";
import { BaseLayout } from "../../layouts";
import { useDebounce } from "../../hooks";

interface IHomeProps {
  children: React.ReactNode;
}
export const Home: React.FC<IHomeProps> = ({ children }) => {
  const { isAgentSelected, selectAgent } = useAgentContext();

  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const theme = useTheme();

  const { userId, userName } = useAuthContext();

  const [cards, setCards] = useState<IAgentList[]>([]);
  const [bemvindo, setBemvindo] = useState("");

  useEffect(() => {
    debounce(() => {
      AgentService.getByUserId(userId).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setCards(result);
          setBemvindo("Bem-vindo " + userName);
        }
      });
    });
  }, [debounce, userId, isAgentSelected]);

  const handleDelete = (id: number) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Realmente deseja apagar?")) {
      AgentService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          console.log(result);
          alert(result.message);
        } else {
          setCards((oldCards) => [
            ...oldCards.filter((oldCard) => oldCard.id !== id),
          ]);
          alert("Agente apagado com sucesso!");
        }
      });
    }
  };

  const newAgent = () => {
    selectAgent(1);
    navigate("/novo-agente");
  };

  if (isAgentSelected) return <>{children}</>;

  return (
    <BaseLayout
      titulo={bemvindo}
      mostrarBotaoTemaLogout
      barraDeFerramentas={
        <ListingTools mostrarInputBusca aoClicarEmNovo={newAgent} />
      }
    >
      <Box width="100%" display="flex">
        <Grid container margin={2}>
          <Grid item container spacing={2}>
            {cards.map((card) => (
              <Grid item xs={8} md={2.5} key={card.id}>
                <Card>
                  <ButtonBase onClick={() => selectAgent(card.id)}>
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{
                            height: theme.spacing(12),
                            width: theme.spacing(12),
                          }}
                          src="/static/images/avatar/1.jpg"
                        />
                      }
                      title={card.nome}
                      subheader={card.classe}
                    />
                    <CardActions>
                      <Box width="100%">
                        <IconButton
                          size="medium"
                          onClick={() => handleDelete(card.id)}
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </Box>
                    </CardActions>
                  </ButtonBase>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </BaseLayout>
  );
};
