import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AuthService } from "../services/api/auth/AuthService";

interface IAuthContextData {
  isAuthenticated: boolean;
  userId: number;
  userName: string;
  login: (name: string, password: string) => Promise<string | void>;
  logout: () => void;
}

const AuthContext = createContext({} as IAuthContextData);

const LOCAL_STORAGE_KEY__ACCESS_TOKEN = "APP_ACCESS_TOKEN";
const LOCAL_STORAGE_KEY__AGENT_ID = "APP_AGENT_ID";

interface IAutoProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<IAutoProviderProps> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string>();
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);

    if (accessToken) {
      setAccessToken(JSON.parse(accessToken));
    } else {
      setAccessToken(undefined);
    }
  }, []);

  const handleLogin = useCallback(async (name: string, password: string) => {
    const result = await AuthService.auth(name, password);

    if (result instanceof Error) {
      console.log(result.message);
      return result.message;
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEY__ACCESS_TOKEN,
        JSON.stringify(result.userId)
      );
      setAccessToken(result.accessToken);
      setUserId(result.userId);
      setUserName(name);
    }
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY__AGENT_ID);
    setAccessToken(undefined);
  }, []);

  const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userId,
        userName,
        login: handleLogin,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
