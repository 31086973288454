import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AgentService } from "../services/api/agent/AgentService";

interface IAgentContextData {
  isAgentSelected: boolean;
  agentId: number;
  selectAgent: (agentId: number) => Promise<string | void>;
  changeAgent: () => void;
}

const AgentContext = createContext({} as IAgentContextData);

const LOCAL_STORAGE_KEY__AGENT_ID = "APP_AGENT_ID";

interface IAutoProviderProps {
  children: React.ReactNode;
}

export const AgentProvider: React.FC<IAutoProviderProps> = ({ children }) => {
  const [agentId, setAgentId] = useState(0);

  useEffect(() => {
    const agentId = localStorage.getItem(LOCAL_STORAGE_KEY__AGENT_ID);

    if (agentId) {
      setAgentId(JSON.parse(agentId));
    } else {
      setAgentId(0);
    }
  }, []);

  const handleSelectAgent = useCallback(async (agentId: number) => {
    const result = await AgentService.getById(agentId);
    console.log(result);

    if (result instanceof Error) {
      console.log(result.message);
      return result.message;
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEY__AGENT_ID,
        JSON.stringify(result.id)
      );
      setAgentId(result.id);
    }
  }, []);

  const handleChangeAgent = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY__AGENT_ID);
    setAgentId(0);
  }, []);

  const isAgentSelected = useMemo(() => !!agentId, [agentId]);

  return (
    <AgentContext.Provider
      value={{
        isAgentSelected,
        agentId,
        selectAgent: handleSelectAgent,
        changeAgent: handleChangeAgent,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};

export const useAgentContext = () => useContext(AgentContext);
