import { BrowserRouter } from "react-router-dom";

import {
  AgentProvider,
  AppThemeProvider,
  DrawerProvider,
  AuthProvider,
} from "./shared/contexts";
import { Home, Login, SideMenu } from "./shared/components";
import { AppRoutes } from "./routes";

export const App = () => {
  return (
    <AuthProvider>
      <AppThemeProvider>
        <Login>
          <AgentProvider>
            <DrawerProvider>
              <BrowserRouter>
                <Home>
                  <SideMenu>
                    <AppRoutes />
                  </SideMenu>
                </Home>
              </BrowserRouter>
            </DrawerProvider>
          </AgentProvider>
        </Login>
      </AppThemeProvider>
    </AuthProvider>
  );
};
