import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Icon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";

import {
  useAgentContext,
  useAppThemeContext,
  useAuthContext,
  useDrawerContext,
} from "../../contexts";
import { useDebounce } from "../../hooks";
import {
  AgentService,
  IAgentDetail,
} from "../../services/api/agent/AgentService";

interface IDrawerProviderProps {
  children: React.ReactNode;
}

interface IListItemProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}

const ListItemLick: React.FC<IListItemProps> = ({
  to,
  icon,
  label,
  onClick,
}) => {
  const navigate = useNavigate();

  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const hadleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <ListItemButton selected={!!match} onClick={hadleClick}>
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

export const SideMenu: React.FC<IDrawerProviderProps> = ({ children }) => {
  const { agentId } = useAgentContext();
  const navigate = useNavigate();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const { isDrawerOpen, toggleDrawerOpen, drawerOptions } = useDrawerContext();
  const { toggleTheme } = useAppThemeContext();
  const { logout } = useAuthContext();
  const { changeAgent } = useAgentContext();
  const { debounce } = useDebounce();

  const [agent, setAgent] = useState<IAgentDetail>();

  useEffect(() => {
    debounce(() => {
      AgentService.getById(agentId).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setAgent(result);
        }
      });
    });
  }, [debounce, agentId]);

  const test = () => {
    changeAgent();
    navigate("/status");
  };

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        variant={smDown ? "temporary" : "permanent"}
        onClose={toggleDrawerOpen}
      >
        <Box
          width={theme.spacing(28)}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            width="100%"
            height={theme.spacing(16)}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Avatar
              sx={{ height: theme.spacing(12), width: theme.spacing(12) }}
              src="/static/images/avatar/1.jpg"
            />
          </Box>

          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingBottom={2}
          >
            {agent?.nome}
          </Box>

          <Divider />

          <Box flex={1}>
            <List component="nav">
              {drawerOptions.map((drawerOptions) => (
                <ListItemLick
                  key={drawerOptions.path}
                  icon={drawerOptions.icon}
                  label={drawerOptions.label}
                  to={drawerOptions.path}
                  onClick={smDown ? toggleDrawerOpen : undefined}
                />
              ))}
            </List>
          </Box>

          <Box>
            <List component="nav">
              <ListItemButton onClick={test}>
                <ListItemIcon>
                  <Icon>assignment_ind</Icon>
                </ListItemIcon>
                <ListItemText primary="Trocar de Agente" />
              </ListItemButton>
              <ListItemButton onClick={toggleTheme}>
                <ListItemIcon>
                  <Icon>dark_mode</Icon>
                </ListItemIcon>
                <ListItemText primary="Alternar Tema" />
              </ListItemButton>
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <Icon>logout</Icon>
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </Box>
        </Box>
      </Drawer>

      <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
        {children}
      </Box>
    </>
  );
};
