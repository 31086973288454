import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";

import { useAuthContext } from "../../contexts";

const loginSchema = yup.object().shape({
  nome: yup.string().required(),
  senha: yup.string().required().min(3),
});

interface ILoginProps {
  children: React.ReactNode;
}
export const Login: React.FC<ILoginProps> = ({ children }) => {
  const { isAuthenticated, login } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");

  const [nomeError, setNomeError] = useState("");
  const [senhaError, setSenhaError] = useState("");

  const handleSubmit = () => {
    setIsLoading(true);

    loginSchema
      .validate({ nome, senha }, { abortEarly: false })
      .then((dadoValidados) => {
        login(dadoValidados.nome, dadoValidados.senha).then(() => {
          setIsLoading(false);
        });
      })
      .catch((errors: yup.ValidationError) => {
        errors.inner.forEach((error) => {
          setIsLoading(false);

          if (error.path === "nome") {
            setNomeError(error.message);
          } else if (error.path === "senha") {
            setSenhaError(error.message);
          }
        });
      });
  };

  if (isAuthenticated) return <>{children}</>;

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" gap={2} width={250}>
            <Typography variant="h6" align="center">
              Identifique-se
            </Typography>

            <TextField
              fullWidth
              label="Nome"
              value={nome}
              disabled={isLoading}
              error={!!nomeError}
              helperText={nomeError}
              onChange={(e) => setNome(e.target.value)}
              onKeyDown={(e) => setNomeError("")}
            />
            <TextField
              fullWidth
              label="Senha"
              type="password"
              value={senha}
              disabled={isLoading}
              error={!!senhaError}
              helperText={senhaError}
              onChange={(e) => setSenha(e.target.value)}
              onKeyDown={(e) => setSenhaError("")}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Box width="100%" display="flex" justifyContent="center">
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={handleSubmit}
              endIcon={
                isLoading ? (
                  <CircularProgress
                    variant="indeterminate"
                    color="inherit"
                    size={20}
                  />
                ) : undefined
              }
            >
              Entrar
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};
