import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { AgentService } from "../../services/api/agent/AgentService";
import { useAgentContext, useAuthContext } from "../../contexts";
import { BaseLayout } from "../../layouts";
import { DetailTools } from "../detail-tools/DetailTools";
import { useVForm, VForm } from "../../forms";
import { useNavigate } from "react-router-dom";

export const CreateAgent = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const { save, formRef } = useVForm();
  const { userId } = useAuthContext();
  const { changeAgent } = useAgentContext();

  const [nome, setNome] = useState("");
  const [agilidade, setAgilidade] = useState("");
  const [forca, setForca] = useState("");
  const [presenca, setPresenca] = useState("");
  const [vigor, setVigor] = useState("");
  const [intelecto, setIntelecto] = useState("");
  const [origem, setOrigem] = useState("");
  const [classe, setClasse] = useState("");
  const [nex, setNex] = useState("");
  const [vida, setVida] = useState("");
  const [esforco, setEsforco] = useState("");
  const [defesa, setDefesa] = useState("");
  const [sanidade, setSanidade] = useState("");

  const handleSalve = (
    nome: string,
    usuarioId: number,
    agilidade: string,
    forca: string,
    presenca: string,
    vigor: string,
    intelecto: string,
    origem: string,
    classe: string,
    nex: string,
    vida: string,
    esforco: string,
    sanidade: string,
    defesa: string
  ) => {
    AgentService.create({
      nome,
      usuarioId,
      agilidade,
      forca,
      presenca,
      vigor,
      intelecto,
      nex,
      vida,
      esforco,
      sanidade,
      defesa,
      origem,
      classe,
    }).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        console.log(result);
      } else {
        alert("Agente Criador com Sucesso!");
        changeAgent();
        navigate("/status");
      }
    });
  };

  return (
    <BaseLayout
      titulo="Novo agente"
      barraDeFerramentas={
        <DetailTools
          mostrarBotaoApagar={false}
          mostrarBotaoNovo={false}
          mostrarBotaoVoltar={false}
          aoClicarEmSalvar={save}
        />
      }
    >
      <VForm
        ref={formRef}
        onSubmit={() =>
          handleSalve(
            nome,
            userId,
            agilidade,
            forca,
            presenca,
            vigor,
            intelecto,
            origem,
            classe,
            nex,
            vida,
            esforco,
            sanidade,
            defesa
          )
        }
      >
        <Box width="100%" display="flex">
          <Grid container margin={mdDown ? "" : 2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h5"
                        >
                          <TextField
                            name="agilidade"
                            size="small"
                            defaultValue={1}
                            onChange={(e) => setAgilidade(e.target.value)}
                          />
                        </Typography>
                        <Typography variant="h5">Agilidade</Typography>
                      </Box>
                    </Box>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box paddingRight={23}>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h5"
                        >
                          <TextField
                            name="forca"
                            size="small"
                            defaultValue={1}
                            onChange={(e) => setForca(e.target.value)}
                          />
                        </Typography>
                        <Typography variant="h5">Força</Typography>
                      </Box>
                      <Box>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h5"
                        >
                          <TextField
                            name="intelecto"
                            size="small"
                            defaultValue={1}
                            onChange={(e) => setIntelecto(e.target.value)}
                          />
                        </Typography>
                        <Typography variant="h5">Intelecto</Typography>
                      </Box>
                    </Box>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box paddingRight={15}>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h5"
                        >
                          <TextField
                            name="presenca"
                            size="small"
                            defaultValue={1}
                            onChange={(e) => setPresenca(e.target.value)}
                          />
                        </Typography>
                        <Typography variant="h5">Presença</Typography>
                      </Box>
                      <Box>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h5"
                        >
                          <TextField
                            name="vigor"
                            size="small"
                            defaultValue={1}
                            onChange={(e) => setVigor(e.target.value)}
                          />
                        </Typography>
                        <Typography variant="h5">Vigor</Typography>
                      </Box>
                    </Box>
                    <Box paddingLeft={2} display="flex">
                      <Typography variant="h5">
                        Nome:{" "}
                        <TextField
                          name="nome"
                          size="small"
                          onChange={(e) => setNome(e.target.value)}
                        />
                      </Typography>
                    </Box>
                    <Box padding={2} display="flex">
                      <Typography variant="h5">
                        Origem:{" "}
                        <TextField
                          name="origem"
                          size="small"
                          onChange={(e) => setOrigem(e.target.value)}
                        />
                      </Typography>
                    </Box>
                    <Box paddingLeft={2} display="flex">
                      <Typography variant="h5">
                        Classe:{" "}
                        <TextField
                          name="classe"
                          size="small"
                          onChange={(e) => setClasse(e.target.value)}
                        />
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h5">NEX</Typography>
                      <TextField
                        name="nex"
                        size="small"
                        defaultValue={0}
                        onChange={(e) => setNex(e.target.value)}
                      />
                      <Typography variant="h5">%</Typography>
                    </Box>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box paddingRight={23}>
                        <Typography variant="h5">Pontos de Vida</Typography>
                        <TextField
                          name="vida"
                          size="small"
                          defaultValue={0}
                          onChange={(e) => setVida(e.target.value)}
                        />
                      </Box>
                      <Box>
                        <Typography variant="h5">Pontos de Esforço</Typography>
                        <TextField
                          name="esfoco"
                          size="small"
                          defaultValue={0}
                          onChange={(e) => setEsforco(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box paddingRight={23}>
                        <Typography variant="h5">Defesa</Typography>
                        <TextField
                          name="defesa"
                          size="small"
                          defaultValue={10}
                          onChange={(e) => setDefesa(e.target.value)}
                        />
                      </Box>
                      <Box>
                        <Typography variant="h5">Pontos de Sanidade</Typography>
                        <TextField
                          name="sanidade"
                          size="small"
                          defaultValue={0}
                          onChange={(e) => setSanidade(e.target.value)}
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </BaseLayout>
  );
};
