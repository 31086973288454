import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";

import {
  Anotacoes,
  HabilidadesRituais,
  Inventario,
  Pericias,
  Status,
} from "../pages";
import { useDrawerContext } from "../shared/contexts";
import { CreateAgent } from "../shared/components";
export const AppRoutes = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
    setDrawerOptions([
      {
        icon: "api",
        path: "/status",
        label: "Status",
      },
      {
        icon: "biotech",
        path: "/periacias",
        label: "Perícias",
      },
      {
        icon: "auto_awesome",
        path: "/habilidades-rituais",
        label: "Habilidades & Rituais",
      },
      {
        icon: "backpack",
        path: "/inventario",
        label: "Inventario",
      },
      {
        icon: "design_services",
        path: "/anotacoes",
        label: "Anotações",
      },
    ]);
  });

  return (
    <Routes>
      <Route path="/novo-agente" element={<CreateAgent />} />

      <Route path="/status" element={<Status />} />
      <Route path="/periacias" element={<Pericias />} />
      <Route path="/habilidades-rituais" element={<HabilidadesRituais />} />
      <Route path="/inventario" element={<Inventario />} />
      <Route path="/anotacoes" element={<Anotacoes />} />

      <Route path="*" element={<Navigate to="/status" />} />
    </Routes>
  );
};
