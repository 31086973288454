import { Api } from "../axios-config";

interface IAuth {
  accessToken: string;
  userId: number;
}

const auth = async (nome: string, senha: string): Promise<IAuth | Error> => {
  try {
    const { data } = await Api.post<IAuth>("/auth2.php", { nome, senha });

    if (data) {
      return data;
    }
    return new Error("Erro no login.");
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro no login."
    );
  }
};

export const AuthService = { auth };
