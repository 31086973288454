import { Api } from "../axios-config";

export interface IAgentList {
  id: number;
  nome: string;
  usuarioId: number;
  agilidade: number;
  forca: number;
  presenca: number;
  vigor: number;
  intelecto: number;
  nex: number;
  vida: number;
  vidaAtual: number;
  esforco: number;
  esforcoAtual: number;
  sanidade: number;
  sanidadeAtual: number;
  defesa: number;
  anotacoes: string;
  inventario: string;
  pericias: string;
  habilidadesRituais: string;
  origem: string;
  classe: string;
}

export interface IAgentDetail {
  id: number;
  nome: string;
  usuarioId: number;
  agilidade: number;
  forca: number;
  presenca: number;
  vigor: number;
  intelecto: number;
  nex: number;
  vida: number;
  vidaAtual: number;
  esforco: number;
  esforcoAtual: number;
  sanidade: number;
  sanidadeAtual: number;
  defesa: number;
  anotacoes: string;
  inventario: string;
  pericias: string;
  habilidadesRituais: string;
  origem: string;
  classe: string;
}

interface ICAgent {
  id: number;
  nome: string;
  usuarioId: number;
  agilidade: string;
  forca: string;
  presenca: string;
  vigor: string;
  intelecto: string;
  nex: string;
  vida: string;
  esforco: string;
  sanidade: string;
  defesa: string;
  origem: string;
  classe: string;
}

const getById = async (id: number): Promise<IAgentDetail | Error> => {
  try {
    const { data } = await Api.get("/getAgentByID.php?id=" + id);
    console.log(data);
    if (data) {
      return data;
    }
    return new Error("Erro ao consultar o registro.");
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao consultar o registro."
    );
  }
};

const getByUserId = async (id: number): Promise<IAgentList[] | Error> => {
  try {
    const { data } = await Api.get("/getByUserID.php?id=" + id);

    if (data) {
      return data.result;
    }
    return new Error("Erro ao consultar o registro.");
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao consultar o registro."
    );
  }
};

const create = async (dados: Omit<ICAgent, "id">): Promise<number | Error> => {
  try {
    const { data } = await Api.post<ICAgent>("/createAgent.php", dados);
    console.log(data);
    if (data) {
      return data.id;
    }
    return new Error("Erro ao criar o registro.");
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao criar o registro."
    );
  }
};

const updateById = async (
  id: number,
  dados: IAgentDetail
): Promise<void | Error> => {
  try {
    await Api.put("/updateUser.php", dados);
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao editar o registro."
    );
  }
};

const updateStatusById = async (
  id: number,
  vidaAtual: number,
  esforcoAtual: number,
  sanidadeAtual: number
): Promise<void | Error> => {
  try {
    await Api.put("/updateStatusById.php", {
      id,
      vidaAtual,
      esforcoAtual,
      sanidadeAtual,
    });
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao editar o registro."
    );
  }
};

const updateAnotacoesById = async (
  id: number,
  anotacoes: string
): Promise<void | Error> => {
  try {
    await Api.put("/updateAnotacoesById.php", { id, anotacoes });
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao editar o registro."
    );
  }
};

const updateInventarioById = async (
  id: number,
  inventario: string
): Promise<void | Error> => {
  try {
    await Api.put("/updateInventarioById.php", { id, inventario });
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao editar o registro."
    );
  }
};

const updatePericiaById = async (
  id: number,
  pericias: string
): Promise<void | Error> => {
  try {
    await Api.put("/updatePericiaById.php", { id, pericias });
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao editar o registro."
    );
  }
};

const updateHabilidadeRitualById = async (
  id: number,
  habilidadesRituais: string
): Promise<void | Error> => {
  try {
    await Api.put("/updateHabilidadeRitualById.php", {
      id,
      habilidadesRituais,
    });
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao editar o registro."
    );
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api.delete("excluirAgent.php?id=" + id);
  } catch (error) {
    console.error(error);

    return new Error(
      (error as { message: string }).message || "Erro ao deletar o registro."
    );
  }
};

export const AgentService = {
  getById,
  getByUserId,
  create,
  updateById,
  updateStatusById,
  updateAnotacoesById,
  updateInventarioById,
  updatePericiaById,
  updateHabilidadeRitualById,
  deleteById,
};
