import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  AgentService,
  IAgentDetail,
} from "../../shared/services/api/agent/AgentService";
import { useAgentContext } from "../../shared/contexts";
import { BaseLayout } from "../../shared/layouts";
import { useDebounce } from "../../shared/hooks";
import { DetailTools } from "../../shared/components";
import { useVForm, VForm } from "../../shared/forms";

export const Status = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { save, formRef } = useVForm();
  const { agentId } = useAgentContext();

  const [agent, setAgent] = useState<IAgentDetail>();
  const [deslocamento, setDeslocamento] = useState(5);
  const [vidaAtual, setVidaAtual] = useState(0);
  const [vida, setVida] = useState(0);
  const [esforcoAtual, setEsforcoAtual] = useState(0);
  const [esforco, setEsforco] = useState(0);
  const [sanidadeAtual, setSanidadeAtual] = useState(0);
  const [sanidade, setSanidade] = useState(0);

  const { debounce } = useDebounce();

  useEffect(() => {
    debounce(() => {
      AgentService.getById(agentId).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setAgent(result);
          if (result.forca === 0) return;
          setDeslocamento(result.forca);
          setVidaAtual(result.vidaAtual);
          setVida(result.vida);
          setEsforcoAtual(result.esforcoAtual);
          setEsforco(result.esforco);
          setSanidadeAtual(result.sanidadeAtual);
          setSanidade(result.sanidade);
        }
      });
    });
  }, []);

  const handleClick = (
    value: number,
    valueMax: number,
    boxName: string,
    operador: string
  ) => {
    console.log(value, valueMax);
    if (boxName === "Vida") {
      if (operador === "+") {
        if (value >= valueMax) return;
        setVidaAtual(value + 1);
      } else {
        if (value === 0) return;
        setVidaAtual(value - 1);
      }
    } else if (boxName === "Esforço") {
      if (operador === "+") {
        if (value >= valueMax) return;
        setEsforcoAtual(value + 1);
      } else {
        if (value === 0) return;
        setEsforcoAtual(value - 1);
      }
    } else {
      if (operador === "+") {
        if (value >= valueMax) return;
        setSanidadeAtual(value + 1);
      } else {
        if (value === 0) return;
        setSanidadeAtual(value - 1);
      }
    }
  };

  const handleSalve = () => {
    AgentService.updateStatusById(
      Number(agentId),
      vidaAtual,
      esforcoAtual,
      sanidadeAtual
    ).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        console.log(result);
      } else {
        alert("Status salvos");
      }
    });
  };

  return (
    <BaseLayout
      titulo="Status"
      barraDeFerramentas={
        <DetailTools
          mostrarBotaoApagar={false}
          mostrarBotaoNovo={false}
          mostrarBotaoVoltar={false}
          aoClicarEmSalvar={save}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSalve}>
        <Box width="100%" display="flex">
          <Grid container margin={mdDown ? 0 : 2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h2"
                        >
                          {agent?.agilidade}
                        </Typography>
                        <Typography variant="h5">Agilidade</Typography>
                      </Box>
                    </Box>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box paddingRight={23}>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h2"
                        >
                          {agent?.forca}
                        </Typography>
                        <Typography variant="h5">Força</Typography>
                      </Box>
                      <Box>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h2"
                        >
                          {agent?.intelecto}
                        </Typography>
                        <Typography variant="h5">Intelecto</Typography>
                      </Box>
                    </Box>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box paddingRight={15}>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h2"
                        >
                          {agent?.presenca}
                        </Typography>
                        <Typography variant="h5">Presença</Typography>
                      </Box>
                      <Box>
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h2"
                        >
                          {agent?.vigor}
                        </Typography>
                        <Typography variant="h5">Vigor</Typography>
                      </Box>
                    </Box>
                    <Box paddingLeft={2} display="flex">
                      <Typography variant="h5">
                        Origem: {agent?.origem}
                      </Typography>
                    </Box>
                    <Box padding={2} display="flex">
                      <Typography variant="h5">
                        Classe: {agent?.classe}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box paddingRight={10} display="flex">
                        <Typography variant="h5" noWrap>
                          NEX: {agent?.nex}%
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography variant="h5" noWrap>
                          Deslocamento: {deslocamento * 5 + 5}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box paddingRight={10} paddingTop={10} paddingBottom={5}>
                        <Typography variant="h5" noWrap>
                          Vida Total: {vida}
                        </Typography>
                        <Typography variant="h6">
                          Vida Atual: {vidaAtual}
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          paddingTop={2}
                        >
                          <IconButton
                            size="large"
                            onClick={() =>
                              handleClick(vidaAtual, vida, "Vida", "+")
                            }
                          >
                            <Icon>add</Icon>
                          </IconButton>
                          <IconButton
                            size="large"
                            onClick={() =>
                              handleClick(vidaAtual, vida, "Vida", "-")
                            }
                          >
                            <Icon>remove</Icon>
                          </IconButton>
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="h5" noWrap>
                          Esfoço Total: {esforco}
                        </Typography>
                        <Typography variant="h6">
                          Esfoço Total: {esforcoAtual}
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          paddingTop={2}
                        >
                          <IconButton
                            size="large"
                            onClick={() =>
                              handleClick(esforcoAtual, esforco, "Esforço", "+")
                            }
                          >
                            <Icon>add</Icon>
                          </IconButton>
                          <IconButton
                            size="large"
                            onClick={() =>
                              handleClick(esforcoAtual, esforco, "Esforço", "-")
                            }
                          >
                            <Icon>remove</Icon>
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box paddingRight={10}>
                        <Typography variant="h5" noWrap>
                          Defesa: {agent?.defesa}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5" noWrap>
                          Sanindade Total: {sanidade}
                        </Typography>
                        <Typography variant="h6">
                          Sanindade Total: {sanidadeAtual}
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          paddingTop={2}
                        >
                          <IconButton
                            size="large"
                            onClick={() =>
                              handleClick(
                                sanidadeAtual,
                                sanidade,
                                "Sanidade",
                                "+"
                              )
                            }
                          >
                            <Icon>add</Icon>
                          </IconButton>
                          <IconButton
                            size="large"
                            onClick={() =>
                              handleClick(
                                sanidadeAtual,
                                sanidade,
                                "Sanidade",
                                "-"
                              )
                            }
                          >
                            <Icon>remove</Icon>
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </BaseLayout>
  );
};
